import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2562a453 = () => interopDefault(import('../pages/403/index.vue' /* webpackChunkName: "pages/403/index" */))
const _7bc9157f = () => interopDefault(import('../pages/adminDashboard/index.vue' /* webpackChunkName: "pages/adminDashboard/index" */))
const _53ed2fae = () => interopDefault(import('../pages/affiliate/index.vue' /* webpackChunkName: "pages/affiliate/index" */))
const _78d3bea0 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _0b0977ab = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _18576c93 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _6423d953 = () => interopDefault(import('../pages/files/index.vue' /* webpackChunkName: "pages/files/index" */))
const _bf820dd0 = () => interopDefault(import('../pages/forgotPassword/index.vue' /* webpackChunkName: "pages/forgotPassword/index" */))
const _659fb70a = () => interopDefault(import('../pages/help/index.vue' /* webpackChunkName: "pages/help/index" */))
const _47fbd63a = () => interopDefault(import('../pages/ledger/index.vue' /* webpackChunkName: "pages/ledger/index" */))
const _66e5ec96 = () => interopDefault(import('../pages/locationOrganizer/index.vue' /* webpackChunkName: "pages/locationOrganizer/index" */))
const _583b07c5 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _0e834601 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _f7732b00 = () => interopDefault(import('../pages/media/index.vue' /* webpackChunkName: "pages/media/index" */))
const _2cc01f5f = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _05aeda5b = () => interopDefault(import('../pages/reports/index.vue' /* webpackChunkName: "pages/reports/index" */))
const _264640b7 = () => interopDefault(import('../pages/scan/index.vue' /* webpackChunkName: "pages/scan/index" */))
const _06351265 = () => interopDefault(import('../pages/wizard/index.vue' /* webpackChunkName: "pages/wizard/index" */))
const _5ece9f7f = () => interopDefault(import('../pages/account/settings/index.vue' /* webpackChunkName: "pages/account/settings/index" */))
const _1ef17118 = () => interopDefault(import('../pages/adminDashboard/custom-invoice.vue' /* webpackChunkName: "pages/adminDashboard/custom-invoice" */))
const _2a4dc349 = () => interopDefault(import('../pages/adminDashboard/email.vue' /* webpackChunkName: "pages/adminDashboard/email" */))
const _2d201da6 = () => interopDefault(import('../pages/adminDashboard/flex-credit-memos.vue' /* webpackChunkName: "pages/adminDashboard/flex-credit-memos" */))
const _8e17866e = () => interopDefault(import('../pages/adminDashboard/payout.vue' /* webpackChunkName: "pages/adminDashboard/payout" */))
const _772af068 = () => interopDefault(import('../pages/adminDashboard/presale-invoices.vue' /* webpackChunkName: "pages/adminDashboard/presale-invoices" */))
const _7306cb92 = () => interopDefault(import('../pages/adminDashboard/rolesAndPermissions.vue' /* webpackChunkName: "pages/adminDashboard/rolesAndPermissions" */))
const _28811fff = () => interopDefault(import('../pages/dashboard/editor.vue' /* webpackChunkName: "pages/dashboard/editor" */))
const _645d6584 = () => interopDefault(import('../pages/dashboard/inProgress.vue' /* webpackChunkName: "pages/dashboard/inProgress" */))
const _2758dcdd = () => interopDefault(import('../pages/dashboard/inProgress1.vue' /* webpackChunkName: "pages/dashboard/inProgress1" */))
const _2766f45e = () => interopDefault(import('../pages/dashboard/inProgress2.vue' /* webpackChunkName: "pages/dashboard/inProgress2" */))
const _e444737a = () => interopDefault(import('../pages/events/archive.vue' /* webpackChunkName: "pages/events/archive" */))
const _1a4a708b = () => interopDefault(import('../pages/events/create.vue' /* webpackChunkName: "pages/events/create" */))
const _72e56f7a = () => interopDefault(import('../pages/events/current.vue' /* webpackChunkName: "pages/events/current" */))
const _640a9115 = () => interopDefault(import('../pages/events/history.vue' /* webpackChunkName: "pages/events/history" */))
const _04f31168 = () => interopDefault(import('../pages/events/media/index.vue' /* webpackChunkName: "pages/events/media/index" */))
const _72ec91c2 = () => interopDefault(import('../pages/forgotPassword/sent.vue' /* webpackChunkName: "pages/forgotPassword/sent" */))
const _524b88ce = () => interopDefault(import('../pages/login/create.vue' /* webpackChunkName: "pages/login/create" */))
const _5f73e05e = () => interopDefault(import('../pages/media/categories.vue' /* webpackChunkName: "pages/media/categories" */))
const _66fea1a5 = () => interopDefault(import('../pages/media/files.vue' /* webpackChunkName: "pages/media/files" */))
const _ce25f41e = () => interopDefault(import('../pages/orders/all/index.vue' /* webpackChunkName: "pages/orders/all/index" */))
const _552adb24 = () => interopDefault(import('../pages/subscriptions/all/index.vue' /* webpackChunkName: "pages/subscriptions/all/index" */))
const _148a40b8 = () => interopDefault(import('../pages/ticket/create.vue' /* webpackChunkName: "pages/ticket/create" */))
const _2d1cbb8a = () => interopDefault(import('../pages/events/media/editor/index.vue' /* webpackChunkName: "pages/events/media/editor/index" */))
const _56929630 = () => interopDefault(import('../pages/orders/events/upcomming.vue' /* webpackChunkName: "pages/orders/events/upcomming" */))
const _560b3055 = () => interopDefault(import('../pages/subscriptions/events/upcomming.vue' /* webpackChunkName: "pages/subscriptions/events/upcomming" */))
const _77fe030f = () => interopDefault(import('../pages/subscriptions/view/cancel/_id.vue' /* webpackChunkName: "pages/subscriptions/view/cancel/_id" */))
const _665ff58a = () => interopDefault(import('../pages/subscriptions/payment/stripe/_orderIncrementId/_pi.vue' /* webpackChunkName: "pages/subscriptions/payment/stripe/_orderIncrementId/_pi" */))
const _d5f45cf8 = () => interopDefault(import('../pages/events/edit/_id.vue' /* webpackChunkName: "pages/events/edit/_id" */))
const _281e59c2 = () => interopDefault(import('../pages/events/view/_id.vue' /* webpackChunkName: "pages/events/view/_id" */))
const _c8b85f5a = () => interopDefault(import('../pages/login/public/_token.vue' /* webpackChunkName: "pages/login/public/_token" */))
const _6e314694 = () => interopDefault(import('../pages/orders/invoices/_id.vue' /* webpackChunkName: "pages/orders/invoices/_id" */))
const _5a926181 = () => interopDefault(import('../pages/orders/payment/_id/index.vue' /* webpackChunkName: "pages/orders/payment/_id/index" */))
const _2060c65a = () => interopDefault(import('../pages/orders/view/_id.vue' /* webpackChunkName: "pages/orders/view/_id" */))
const _4dbe8081 = () => interopDefault(import('../pages/subscriptions/invoices/_id.vue' /* webpackChunkName: "pages/subscriptions/invoices/_id" */))
const _869b589e = () => interopDefault(import('../pages/subscriptions/item/_id/index.vue' /* webpackChunkName: "pages/subscriptions/item/_id/index" */))
const _03cd53b4 = () => interopDefault(import('../pages/subscriptions/payment/_id/index.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/index" */))
const _50b8d540 = () => interopDefault(import('../pages/subscriptions/view/_id.vue' /* webpackChunkName: "pages/subscriptions/view/_id" */))
const _00d44904 = () => interopDefault(import('../pages/account/settings/_field/_token/index.vue' /* webpackChunkName: "pages/account/settings/_field/_token/index" */))
const _1467d8ec = () => interopDefault(import('../pages/customer/events/_eventId/_orderItemId.vue' /* webpackChunkName: "pages/customer/events/_eventId/_orderItemId" */))
const _5e28ccca = () => interopDefault(import('../pages/orders/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/orders/payment/_id/_payment_method/_status" */))
const _13868f86 = () => interopDefault(import('../pages/subscriptions/payment/_id/_payment_method/_status.vue' /* webpackChunkName: "pages/subscriptions/payment/_id/_payment_method/_status" */))
const _1d7a059e = () => interopDefault(import('../pages/autologin/_id.vue' /* webpackChunkName: "pages/autologin/_id" */))
const _ede115fe = () => interopDefault(import('../pages/check-in/_locationId.vue' /* webpackChunkName: "pages/check-in/_locationId" */))
const _100ed562 = () => interopDefault(import('../pages/files/_orderItemId/index.vue' /* webpackChunkName: "pages/files/_orderItemId/index" */))
const _384249af = () => interopDefault(import('../pages/Link/_hash.vue' /* webpackChunkName: "pages/Link/_hash" */))
const _8774c454 = () => interopDefault(import('../pages/locationOrganizer/_locationId/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/index" */))
const _34ed8897 = () => interopDefault(import('../pages/login/_token.vue' /* webpackChunkName: "pages/login/_token" */))
const _44e6456b = () => interopDefault(import('../pages/media/_id/index.vue' /* webpackChunkName: "pages/media/_id/index" */))
const _35994d3c = () => interopDefault(import('../pages/ticket/_customerMembershipId.vue' /* webpackChunkName: "pages/ticket/_customerMembershipId" */))
const _57df1e30 = () => interopDefault(import('../pages/locationOrganizer/_locationId/all/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/all/index" */))
const _787ef84d = () => interopDefault(import('../pages/locationOrganizer/_locationId/ticket-stats/index.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/ticket-stats/index" */))
const _cdaf0a14 = () => interopDefault(import('../pages/scan/_hash/public.vue' /* webpackChunkName: "pages/scan/_hash/public" */))
const _3b588317 = () => interopDefault(import('../pages/locationOrganizer/_locationId/event/_eventId.vue' /* webpackChunkName: "pages/locationOrganizer/_locationId/event/_eventId" */))
const _3ce9b8f7 = () => interopDefault(import('../pages/files/_orderItemId/_eventDateId.vue' /* webpackChunkName: "pages/files/_orderItemId/_eventDateId" */))
const _26d6f15b = () => interopDefault(import('../pages/media/_id/_eventDateId.vue' /* webpackChunkName: "pages/media/_id/_eventDateId" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/403",
    component: _2562a453,
    name: "403___de"
  }, {
    path: "/adminDashboard",
    component: _7bc9157f,
    name: "adminDashboard___de"
  }, {
    path: "/affiliate",
    component: _53ed2fae,
    name: "affiliate___de"
  }, {
    path: "/dashboard",
    component: _78d3bea0,
    name: "dashboard___de"
  }, {
    path: "/en",
    component: _0b0977ab,
    name: "index___en"
  }, {
    path: "/events",
    component: _18576c93,
    name: "events___de"
  }, {
    path: "/files",
    component: _6423d953,
    name: "files___de"
  }, {
    path: "/forgotPassword",
    component: _bf820dd0,
    name: "forgotPassword___de"
  }, {
    path: "/help",
    component: _659fb70a,
    name: "help___de"
  }, {
    path: "/ledger",
    component: _47fbd63a,
    name: "ledger___de"
  }, {
    path: "/locationOrganizer",
    component: _66e5ec96,
    name: "locationOrganizer___de"
  }, {
    path: "/login",
    component: _583b07c5,
    name: "login___de"
  }, {
    path: "/logout",
    component: _0e834601,
    name: "logout___de"
  }, {
    path: "/media",
    component: _f7732b00,
    name: "media___de"
  }, {
    path: "/orders",
    component: _2cc01f5f,
    name: "orders___de"
  }, {
    path: "/reports",
    component: _05aeda5b,
    name: "reports___de"
  }, {
    path: "/scan",
    component: _264640b7,
    name: "scan___de"
  }, {
    path: "/wizard",
    component: _06351265,
    name: "wizard___de"
  }, {
    path: "/account/settings",
    component: _5ece9f7f,
    name: "account-settings___de"
  }, {
    path: "/adminDashboard/custom-invoice",
    component: _1ef17118,
    name: "adminDashboard-custom-invoice___de"
  }, {
    path: "/adminDashboard/email",
    component: _2a4dc349,
    name: "adminDashboard-email___de"
  }, {
    path: "/adminDashboard/flex-credit-memos",
    component: _2d201da6,
    name: "adminDashboard-flex-credit-memos___de"
  }, {
    path: "/adminDashboard/payout",
    component: _8e17866e,
    name: "adminDashboard-payout___de"
  }, {
    path: "/adminDashboard/presale-invoices",
    component: _772af068,
    name: "adminDashboard-presale-invoices___de"
  }, {
    path: "/adminDashboard/rolesAndPermissions",
    component: _7306cb92,
    name: "adminDashboard-rolesAndPermissions___de"
  }, {
    path: "/dashboard/editor",
    component: _28811fff,
    name: "dashboard-editor___de"
  }, {
    path: "/dashboard/inProgress",
    component: _645d6584,
    name: "dashboard-inProgress___de"
  }, {
    path: "/dashboard/inProgress1",
    component: _2758dcdd,
    name: "dashboard-inProgress1___de"
  }, {
    path: "/dashboard/inProgress2",
    component: _2766f45e,
    name: "dashboard-inProgress2___de"
  }, {
    path: "/en/403",
    component: _2562a453,
    name: "403___en"
  }, {
    path: "/en/adminDashboard",
    component: _7bc9157f,
    name: "adminDashboard___en"
  }, {
    path: "/en/affiliate",
    component: _53ed2fae,
    name: "affiliate___en"
  }, {
    path: "/en/dashboard",
    component: _78d3bea0,
    name: "dashboard___en"
  }, {
    path: "/en/events",
    component: _18576c93,
    name: "events___en"
  }, {
    path: "/en/files",
    component: _6423d953,
    name: "files___en"
  }, {
    path: "/en/forgotPassword",
    component: _bf820dd0,
    name: "forgotPassword___en"
  }, {
    path: "/en/help",
    component: _659fb70a,
    name: "help___en"
  }, {
    path: "/en/ledger",
    component: _47fbd63a,
    name: "ledger___en"
  }, {
    path: "/en/locationOrganizer",
    component: _66e5ec96,
    name: "locationOrganizer___en"
  }, {
    path: "/en/login",
    component: _583b07c5,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _0e834601,
    name: "logout___en"
  }, {
    path: "/en/media",
    component: _f7732b00,
    name: "media___en"
  }, {
    path: "/en/orders",
    component: _2cc01f5f,
    name: "orders___en"
  }, {
    path: "/en/reports",
    component: _05aeda5b,
    name: "reports___en"
  }, {
    path: "/en/scan",
    component: _264640b7,
    name: "scan___en"
  }, {
    path: "/en/wizard",
    component: _06351265,
    name: "wizard___en"
  }, {
    path: "/events/archive",
    component: _e444737a,
    name: "events-archive___de"
  }, {
    path: "/events/create",
    component: _1a4a708b,
    name: "events-create___de"
  }, {
    path: "/events/current",
    component: _72e56f7a,
    name: "events-current___de"
  }, {
    path: "/events/history",
    component: _640a9115,
    name: "events-history___de"
  }, {
    path: "/events/media",
    component: _04f31168,
    name: "events-media___de"
  }, {
    path: "/forgotPassword/sent",
    component: _72ec91c2,
    name: "forgotPassword-sent___de"
  }, {
    path: "/login/create",
    component: _524b88ce,
    name: "login-create___de"
  }, {
    path: "/media/categories",
    component: _5f73e05e,
    name: "media-categories___de"
  }, {
    path: "/media/files",
    component: _66fea1a5,
    name: "media-files___de"
  }, {
    path: "/orders/all",
    component: _ce25f41e,
    name: "orders-all___de"
  }, {
    path: "/subscriptions/all",
    component: _552adb24,
    name: "subscriptions-all___de"
  }, {
    path: "/ticket/create",
    component: _148a40b8,
    name: "ticket-create___de"
  }, {
    path: "/en/account/settings",
    component: _5ece9f7f,
    name: "account-settings___en"
  }, {
    path: "/en/adminDashboard/custom-invoice",
    component: _1ef17118,
    name: "adminDashboard-custom-invoice___en"
  }, {
    path: "/en/adminDashboard/email",
    component: _2a4dc349,
    name: "adminDashboard-email___en"
  }, {
    path: "/en/adminDashboard/flex-credit-memos",
    component: _2d201da6,
    name: "adminDashboard-flex-credit-memos___en"
  }, {
    path: "/en/adminDashboard/payout",
    component: _8e17866e,
    name: "adminDashboard-payout___en"
  }, {
    path: "/en/adminDashboard/presale-invoices",
    component: _772af068,
    name: "adminDashboard-presale-invoices___en"
  }, {
    path: "/en/adminDashboard/rolesAndPermissions",
    component: _7306cb92,
    name: "adminDashboard-rolesAndPermissions___en"
  }, {
    path: "/en/dashboard/editor",
    component: _28811fff,
    name: "dashboard-editor___en"
  }, {
    path: "/en/dashboard/inProgress",
    component: _645d6584,
    name: "dashboard-inProgress___en"
  }, {
    path: "/en/dashboard/inProgress1",
    component: _2758dcdd,
    name: "dashboard-inProgress1___en"
  }, {
    path: "/en/dashboard/inProgress2",
    component: _2766f45e,
    name: "dashboard-inProgress2___en"
  }, {
    path: "/en/events/archive",
    component: _e444737a,
    name: "events-archive___en"
  }, {
    path: "/en/events/create",
    component: _1a4a708b,
    name: "events-create___en"
  }, {
    path: "/en/events/current",
    component: _72e56f7a,
    name: "events-current___en"
  }, {
    path: "/en/events/history",
    component: _640a9115,
    name: "events-history___en"
  }, {
    path: "/en/events/media",
    component: _04f31168,
    name: "events-media___en"
  }, {
    path: "/en/forgotPassword/sent",
    component: _72ec91c2,
    name: "forgotPassword-sent___en"
  }, {
    path: "/en/login/create",
    component: _524b88ce,
    name: "login-create___en"
  }, {
    path: "/en/media/categories",
    component: _5f73e05e,
    name: "media-categories___en"
  }, {
    path: "/en/media/files",
    component: _66fea1a5,
    name: "media-files___en"
  }, {
    path: "/en/orders/all",
    component: _ce25f41e,
    name: "orders-all___en"
  }, {
    path: "/en/subscriptions/all",
    component: _552adb24,
    name: "subscriptions-all___en"
  }, {
    path: "/en/ticket/create",
    component: _148a40b8,
    name: "ticket-create___en"
  }, {
    path: "/events/media/editor",
    component: _2d1cbb8a,
    name: "events-media-editor___de"
  }, {
    path: "/orders/events/upcomming",
    component: _56929630,
    name: "orders-events-upcomming___de"
  }, {
    path: "/subscriptions/events/upcomming",
    component: _560b3055,
    name: "subscriptions-events-upcomming___de"
  }, {
    path: "/en/events/media/editor",
    component: _2d1cbb8a,
    name: "events-media-editor___en"
  }, {
    path: "/en/orders/events/upcomming",
    component: _56929630,
    name: "orders-events-upcomming___en"
  }, {
    path: "/en/subscriptions/events/upcomming",
    component: _560b3055,
    name: "subscriptions-events-upcomming___en"
  }, {
    path: "/en/subscriptions/view/cancel/:id?",
    component: _77fe030f,
    name: "subscriptions-view-cancel-id___en"
  }, {
    path: "/en/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _665ff58a,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___en"
  }, {
    path: "/en/events/edit/:id?",
    component: _d5f45cf8,
    name: "events-edit-id___en"
  }, {
    path: "/en/events/view/:id?",
    component: _281e59c2,
    name: "events-view-id___en"
  }, {
    path: "/en/login/public/:token?",
    component: _c8b85f5a,
    name: "login-public-token___en"
  }, {
    path: "/en/orders/invoices/:id?",
    component: _6e314694,
    name: "orders-invoices-id___en"
  }, {
    path: "/en/orders/payment/:id?",
    component: _5a926181,
    name: "orders-payment-id___en"
  }, {
    path: "/en/orders/view/:id?",
    component: _2060c65a,
    name: "orders-view-id___en"
  }, {
    path: "/en/subscriptions/invoices/:id?",
    component: _4dbe8081,
    name: "subscriptions-invoices-id___en"
  }, {
    path: "/en/subscriptions/item/:id?",
    component: _869b589e,
    name: "subscriptions-item-id___en"
  }, {
    path: "/en/subscriptions/payment/:id?",
    component: _03cd53b4,
    name: "subscriptions-payment-id___en"
  }, {
    path: "/en/subscriptions/view/:id?",
    component: _50b8d540,
    name: "subscriptions-view-id___en"
  }, {
    path: "/subscriptions/view/cancel/:id?",
    component: _77fe030f,
    name: "subscriptions-view-cancel-id___de"
  }, {
    path: "/en/account/settings/:field/:token?",
    component: _00d44904,
    name: "account-settings-field-token___en"
  }, {
    path: "/en/customer/events/:eventId?/:orderItemId?",
    component: _1467d8ec,
    name: "customer-events-eventId-orderItemId___en"
  }, {
    path: "/subscriptions/payment/stripe/:orderIncrementId?/:pi?",
    component: _665ff58a,
    name: "subscriptions-payment-stripe-orderIncrementId-pi___de"
  }, {
    path: "/en/orders/payment/:id?/:payment_method/:status?",
    component: _5e28ccca,
    name: "orders-payment-id-payment_method-status___en"
  }, {
    path: "/en/subscriptions/payment/:id?/:payment_method/:status?",
    component: _13868f86,
    name: "subscriptions-payment-id-payment_method-status___en"
  }, {
    path: "/en/autologin/:id?",
    component: _1d7a059e,
    name: "autologin-id___en"
  }, {
    path: "/en/check-in/:locationId?",
    component: _ede115fe,
    name: "check-in-locationId___en"
  }, {
    path: "/en/files/:orderItemId",
    component: _100ed562,
    name: "files-orderItemId___en"
  }, {
    path: "/en/Link/:hash?",
    component: _384249af,
    name: "Link-hash___en"
  }, {
    path: "/en/locationOrganizer/:locationId",
    component: _8774c454,
    name: "locationOrganizer-locationId___en"
  }, {
    path: "/en/login/:token",
    component: _34ed8897,
    name: "login-token___en"
  }, {
    path: "/en/media/:id",
    component: _44e6456b,
    name: "media-id___en"
  }, {
    path: "/en/ticket/:customerMembershipId?",
    component: _35994d3c,
    name: "ticket-customerMembershipId___en"
  }, {
    path: "/events/edit/:id?",
    component: _d5f45cf8,
    name: "events-edit-id___de"
  }, {
    path: "/events/view/:id?",
    component: _281e59c2,
    name: "events-view-id___de"
  }, {
    path: "/login/public/:token?",
    component: _c8b85f5a,
    name: "login-public-token___de"
  }, {
    path: "/orders/invoices/:id?",
    component: _6e314694,
    name: "orders-invoices-id___de"
  }, {
    path: "/orders/payment/:id?",
    component: _5a926181,
    name: "orders-payment-id___de"
  }, {
    path: "/orders/view/:id?",
    component: _2060c65a,
    name: "orders-view-id___de"
  }, {
    path: "/subscriptions/invoices/:id?",
    component: _4dbe8081,
    name: "subscriptions-invoices-id___de"
  }, {
    path: "/subscriptions/item/:id?",
    component: _869b589e,
    name: "subscriptions-item-id___de"
  }, {
    path: "/subscriptions/payment/:id?",
    component: _03cd53b4,
    name: "subscriptions-payment-id___de"
  }, {
    path: "/subscriptions/view/:id?",
    component: _50b8d540,
    name: "subscriptions-view-id___de"
  }, {
    path: "/en/locationOrganizer/:locationId/all",
    component: _57df1e30,
    name: "locationOrganizer-locationId-all___en"
  }, {
    path: "/en/locationOrganizer/:locationId/ticket-stats",
    component: _787ef84d,
    name: "locationOrganizer-locationId-ticket-stats___en"
  }, {
    path: "/en/scan/:hash/public",
    component: _cdaf0a14,
    name: "scan-hash-public___en"
  }, {
    path: "/en/locationOrganizer/:locationId/event/:eventId?",
    component: _3b588317,
    name: "locationOrganizer-locationId-event-eventId___en"
  }, {
    path: "/account/settings/:field/:token?",
    component: _00d44904,
    name: "account-settings-field-token___de"
  }, {
    path: "/customer/events/:eventId?/:orderItemId?",
    component: _1467d8ec,
    name: "customer-events-eventId-orderItemId___de"
  }, {
    path: "/en/files/:orderItemId/:eventDateId",
    component: _3ce9b8f7,
    name: "files-orderItemId-eventDateId___en"
  }, {
    path: "/en/media/:id/:eventDateId",
    component: _26d6f15b,
    name: "media-id-eventDateId___en"
  }, {
    path: "/orders/payment/:id?/:payment_method/:status?",
    component: _5e28ccca,
    name: "orders-payment-id-payment_method-status___de"
  }, {
    path: "/subscriptions/payment/:id?/:payment_method/:status?",
    component: _13868f86,
    name: "subscriptions-payment-id-payment_method-status___de"
  }, {
    path: "/autologin/:id?",
    component: _1d7a059e,
    name: "autologin-id___de"
  }, {
    path: "/check-in/:locationId?",
    component: _ede115fe,
    name: "check-in-locationId___de"
  }, {
    path: "/files/:orderItemId",
    component: _100ed562,
    name: "files-orderItemId___de"
  }, {
    path: "/Link/:hash?",
    component: _384249af,
    name: "Link-hash___de"
  }, {
    path: "/locationOrganizer/:locationId",
    component: _8774c454,
    name: "locationOrganizer-locationId___de"
  }, {
    path: "/login/:token",
    component: _34ed8897,
    name: "login-token___de"
  }, {
    path: "/media/:id",
    component: _44e6456b,
    name: "media-id___de"
  }, {
    path: "/ticket/:customerMembershipId?",
    component: _35994d3c,
    name: "ticket-customerMembershipId___de"
  }, {
    path: "/locationOrganizer/:locationId/all",
    component: _57df1e30,
    name: "locationOrganizer-locationId-all___de"
  }, {
    path: "/locationOrganizer/:locationId/ticket-stats",
    component: _787ef84d,
    name: "locationOrganizer-locationId-ticket-stats___de"
  }, {
    path: "/scan/:hash/public",
    component: _cdaf0a14,
    name: "scan-hash-public___de"
  }, {
    path: "/locationOrganizer/:locationId/event/:eventId?",
    component: _3b588317,
    name: "locationOrganizer-locationId-event-eventId___de"
  }, {
    path: "/files/:orderItemId/:eventDateId",
    component: _3ce9b8f7,
    name: "files-orderItemId-eventDateId___de"
  }, {
    path: "/media/:id/:eventDateId",
    component: _26d6f15b,
    name: "media-id-eventDateId___de"
  }, {
    path: "/",
    component: _0b0977ab,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
